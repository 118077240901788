<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <ion-toolbar class="text-center">
        <ion-buttons slot="start">
          <ion-button @click.prevent="goBack()">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>Edit Page</ion-title>
      </ion-toolbar>
    </ion-header> 
    <!-- Page Content -->
    <ion-content>
      <!--Authorized -->
      <div v-if="isAdmin === true" class="app-max-width">
        <ion-toolbar color="light">
          <ion-segment scrollable :value="view">
            <ion-segment-button value="profile" @click="view = 'profile'">
              Profile
            </ion-segment-button>
            <ion-segment-button value="form" @click="view = 'form'">
              Edit Page
            </ion-segment-button>
            <ion-segment-button value="photo" @click="view = 'photo'">
              Photo
            </ion-segment-button>
            <ion-segment-button value="genres" @click="view = 'genres'">
              Genres
            </ion-segment-button>
            <ion-segment-button value="relationships" @click.prevent="view = 'relationships'">
              Relationships
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>

        <!-- Wait for page id -->
        <div v-if="pageLoaded === true">
          <!-- Page Form -->
          <div v-if="view == 'profile'">
            <!-- Name -->
            <ion-item>
              <div tabindex="0"></div>
              <ion-avatar v-if="page.image != null && page.image != ''" slot="start">
                <img :src="page.image" >
              </ion-avatar>
              <ion-label>
                {{page.name}} <ion-icon class="margin-left-sm" v-if="page.official > 0" :icon="checkmark"></ion-icon>
                <p>
                  <span class="margin-left-sm text-medium">#{{page.slug}}</span>
                </p>
              </ion-label>
            </ion-item>   
            <!-- Description -->         
            <ion-item v-if="page.description != null && page.description != ''">
              <div tabindex="0"></div>
              <p>{{page.description}}</p>
            </ion-item> 
            <!-- Street Address -->         
            <ion-item v-if="page.street_address != null && page.street_address != ''">
              <div tabindex="0"></div>
              {{page.street_address}}
            </ion-item> 
            <!--Location -->         
            <ion-item>
              <div tabindex="0"></div>
              <span v-if="page.city != null && page.city != ''" class="margin-right-sm">{{page.city}},</span>
              <span v-if="page.state != null && page.state != ''" class="margin-right-sm text-uppercase">{{page.state}}</span>
              <span v-if="page.zip_code != null && page.zip_code != ''" class="margin-right-sm">{{page.zip_code}}</span>
              <span v-if="page.country != null && page.country != ''" class="text-uppercase">{{page.country}}</span>
            </ion-item>  
            <!-- URL -->         
            <ion-item v-if="page.url != null && page.url != ''">
              <div tabindex="0"></div>
              <a :href="page.url" target="_blank">{{page.url}}</a>
            </ion-item> 

            <PagePosts :page-id="page.id" />
          </div>
          <!-- Page Form -->
          <div v-if="view == 'form'">
            <PageEdit :page-data="page" />
          </div>
          <!-- Page Photo -->
          <div v-if="view == 'photo'">
              <PagePhoto :page-id="pageId" :page-img="page.image" post-type="update" />
          </div>
          <!-- Page Genres -->
          <div v-if="view == 'genres'" class="text-center padding-md">
            <PageGenres :page-id="pageId" />
          </div>
          <!-- Page Relationships -->
          <div v-if="view == 'relationships'">
            <PageRelationships :page-id="pageId" />
          </div>
        </div>
      </div>
      <!-- Not authorized -->
      <div v-else class="app-max-width">
        Not authorized
      </div>
    </ion-content>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonSegment, IonSegmentButton, IonAvatar, IonItem, IonLabel } from '@ionic/vue';
import { chevronBack, add, reorderThree, checkmark } from 'ionicons/icons';
import { defineComponent, ref, onMounted } from 'vue';
import doAuth from '../../services/auth';
import { useRouter } from 'vue-router';
import PageEdit from '../../components/PageEdit.vue';
import PagePhoto from '../../components/PagePhoto.vue';
import { useStore } from "vuex";
import apiClient from '../../services/api';
import PageGenres from '../../components/PageGenres.vue';
import PageRelationships from '../../components/PageRelationships.vue';
import PagePosts from '../../components/PagePosts.vue';

export default defineComponent({
  name: 'AdminPageEdit',
  components: {
    IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonSegment, IonSegmentButton, PageEdit, PagePhoto, IonAvatar, IonItem, IonLabel, PageGenres, PageRelationships, PagePosts
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const isAdmin = ref(true);
    const pageId = ref(0);
    const page = ref([]);
    const view = ref('profile');
    const store = useStore();
    const pageLoaded = ref(false);

    onMounted(() => {
      pageId.value = router.currentRoute._rawValue.params.id
    })

    function getPage() {
      //presentLoading()
      apiClient.get('/api/get/page/'+pageId.value, {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      }).then(response => {
        page.value = response.data
      }).catch(error => {
        console.log(error)  
      });          
    }

    function updateRoute(val) {
      router.push(val)
    }

    function goBack() {
      router.go(-1)
    }

    return {
      chevronBack, add, router, updateRoute, goBack, isAdmin, reorderThree, pageId, view, page, getPage, pageLoaded, checkmark
    }
  },
  watch: {
    pageId: function() {
      if(this.pageId > 0) {
        this.getPage()
      }
    },
    page: {
      handler() {
        if(this.pageId > 0) {
          if(this.page) {
            this.pageLoaded = true
          } else {
            this.pageLoaded = false
          }
        } else {
          this.pageLoaded = false
        }
      },
      deep:true
    }
  }
});
</script>

<style scoped>

</style>