<template>
	<div>
		<!-- Name -->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="name" type="text" placeholder="Name*"></ion-input>
		</ion-item>	
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="slug" type="text" placeholder="Slug*"></ion-input>
		</ion-item>	
		<!-- Description -->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-textarea v-model="description" type="text" placeholder="Description"></ion-textarea>
		</ion-item>	
		<!-- Street Address -->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="streetAddress" type="text" placeholder="Street Address"></ion-input>
		</ion-item>	
		<!-- Street Address Required -->
		<ion-item v-if="addressRequired === true">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			Street address and zip code are required for venue pages.
		</ion-item>	
		<!-- City -->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="city" type="text" placeholder="City*"></ion-input>
		</ion-item>	
		<!-- Scene -->
		<div v-if="scenes.length > 0" class="padding-top-md">
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-label position="stacked">Scene</ion-label>
				<ion-select placeholder="Select One" v-model="sceneId">
					<ion-select-option v-for="item in scenes" :key="item" :value="item.id">{{item.name}}, {{item.state}}</ion-select-option>
				</ion-select>
			</ion-item>
		</div>
		<!-- State -->
		<div v-if="states.length > 0" class="padding-top-md">
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-label position="stacked">State</ion-label>
				<ion-select placeholder="Select One" v-model="state">
					<ion-select-option v-for="item in states" :key="item" :value="item.state.toLowerCase()">{{item.name}}</ion-select-option>
				</ion-select>
			</ion-item>
		</div>
		<!-- Zip -->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="zipCode" type="text" placeholder="Zip code"></ion-input>
		</ion-item>	
		<!-- Country -->
		<div v-if="countries.length > 0" class="padding-top-md">
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-label position="stacked">Country</ion-label>
				<ion-select placeholder="Select One" v-model="country">
					<ion-select-option v-for="item in countries" :key="item" :value="item.slug.toLowerCase()">{{item.name}}</ion-select-option>
				</ion-select>
			</ion-item>
		</div>
		<!-- URL -->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="url" type="text" placeholder="Website"></ion-input>
		</ion-item>	
		<!-- 
			Status 
		-->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-label position="stacked">Status</ion-label>
			<ion-select placeholder="Select One" v-model="status">
				<ion-select-option :value="1">Published</ion-select-option>
				<ion-select-option :value="0">Unpublished</ion-select-option>
				<ion-select-option :value="2">Delete</ion-select-option>
			</ion-select>
		</ion-item>
		<!-- Submit -->
		<ion-item v-if="isPageFormValidated === true">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-button slot="end" @click.prevent="updatePage()">
				Save
			</ion-button>
		</ion-item>	
	</div>
</template>

<script>
import { IonItem, IonInput, IonTextarea, IonLabel, IonButton, IonSelect, IonSelectOption, loadingController, toastController } from '@ionic/vue';
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from "vuex";
import apiClient from '../services/api';

export default defineComponent({
	name: 'PageEdit',
	props: ['pageData'],
	components: {
		IonItem, IonInput, IonTextarea, IonLabel, IonButton, IonSelect, IonSelectOption
	},
	setup(props) {
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const page = ref([]);
		const name = ref(null);
		const slug = ref(null);
		const description = ref(null);
		const streetAddress = ref(null);
		const city = ref(null);
		const sceneId = ref(0);
		const state = ref(null);
		const country = ref('us');
		const zipCode = ref(null);
		const url = ref(null);
		const status = ref(1);
		const isPageFormValidated = ref(false);
		const addressRequired = ref(false);
		const scenes = ref([]);
		const states = ref([]);
		const regions = ref([]);
		const countries = ref([]);
		const toastMessage = ref(null);
		const timeout = { default: 6000 }
		const isError = ref(false);
		const errors = ref([]);

		onMounted(() => {
			if(props.pageData) {
				page.value = props.pageData
			}
		})

		function getScenesByType(sceneType) {
			//presentLoading()
			apiClient.get('/api/get/scenes/type/' + sceneType, {
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				// Scene
				if(sceneType == 'metro') {
					scenes.value = response.data
				}
				// State
				if(sceneType == 'state') {
					states.value = response.data
				}
				// Region
				if(sceneType == 'region') {
					regions.value = response.data
				}
				// Country
				if(sceneType == 'country') {
					countries.value = response.data
				}

			}).catch(error => {
				console.log(error)  
			});      
		}

		function validatePageForm() {
			// Name
			if(name.value != null && name.value != '') {
				// Slug
				if(slug.value != null && slug.value != '') {				
					// City
					if(city.value != null && city.value != '') {
						// State
						if(state.value != null && state.value != '') {
							// Country
							if(country.value != null && country.value != '') {
								// If venue
								if(page.value.category_id == 1) {
									if(streetAddress.value === null || streetAddress.value == '') {
										addressRequired.value = true
										isPageFormValidated.value = false
									} else {
										addressRequired.value = false
										isPageFormValidated.value = true									
									}
								} else {
									isPageFormValidated.value = true
								}
							} else {
								isPageFormValidated.value = false
							}
						} else {
							isPageFormValidated.value = false
						}
					} else {
						isPageFormValidated.value = false
					}
				} else {
					isPageFormValidated.value = false
				}
			} else {
				isPageFormValidated.value = false
			}
		}

		function updatePage() {
			presentLoading()
			apiClient.post('/api/update/page/'+page.value.id, 
			{
				name: name.value,
				slug: slug.value, 
				description: description.value,
				street_address: streetAddress.value,
				city: city.value,
				scene_id: sceneId.value,
				state: state.value,
				country: country.value,
				zip_code: zipCode.value,
				url: url.value,
				status: status.value,
			},
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			})
			.then(response => {
				// Success
				if(response.data.message == 'success') {
					toastMessage.value = 'You have updated a page.'
					openToast()
				}
				// Success
				if(response.data.message == 'success-delete') {
					toastMessage.value = 'You have deleted a page.'
					openToast()
					resetForm()
					window.location = '/admin-pages'
				}
				// Fail 
				if(response.data.message == 'fail') {
					toastMessage.value = 'There was a problem.'
					openToast()
					isError.value = true
					errors.value = response.data.errors            
				}
				// Fail Validation
				if(response.data.message == 'fail-validation') {
					toastMessage.value = 'There was a problem.'
					openToast()
					isError.value = true
					errors.value = response.data.errors            
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					toastMessage.value = 'You are not authorized to do this.'
					openToast()
				}
				// Fail Auth
				if(response.data.message == 'fail-slug') {
					toastMessage.value = 'That hashtag is already taken.'
					openToast()
				}
			})
			.catch(error => {
				// credentials didn't match
				isError.value = true
				errors.value = error.response.data.errors
			}); 
		}

		function resetForm() {
			name.value = null
			slug.value = null
			description.value = null
			streetAddress.value = null
			city.value = null
			sceneId.value = 0
			state.value = null
			country.value = null
			zipCode.value = null
			url.value = null
			status.value = 1
			addressRequired.value = false	
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
				{
					icon: 'close',
					role: 'cancel',
					handler: () => {

					}
				}]
			})
			return toast.present();
		}

		return {
			authUser, page, name, description, streetAddress, city, sceneId, state, country, zipCode, url, status, isPageFormValidated, scenes, states, regions, countries, addressRequired, slug, getScenesByType, validatePageForm, isError, errors, updatePage
		}
	},
	watch: {
		page: {
			handler() {
				if(this.page) {
					this.name = this.page.name
					this.slug = this.page.slug
					this.description = this.page.description
					this.streetAddress = this.page.street_address
					this.city = this.page.city
					this.zipCode = this.page.zip_code
					this.url = this.page.url
					this.status = this.page.status
					this.sceneId = this.page.scene_id
					this.state = this.page.state
					this.country = this.page.country
					this.getScenesByType('metro')
					this.getScenesByType('state')
					this.getScenesByType('region')
					this.getScenesByType('country')
				}
			},
			deep:true
		},
		name: function() {
			this.validatePageForm()
		},
		slug: function() {
			this.validatePageForm()
		},
		description: function() {
			this.validatePageForm()
		},
		streetAddress: function() {
			this.validatePageForm()
		},
		city: function() {
			this.validatePageForm()
		},
		state: function() {
			this.validatePageForm()
		},
		country: function() {
			this.validatePageForm()
		},
		zipCode: function() {
			this.validatePageForm()
		},
		url: function() {
			this.validatePageForm()
		},
		status: function() {
			this.validatePageForm()
		},
	}
});
</script>