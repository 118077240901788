<template>
	<div>
		<!-- Step 1 -->
		<div v-if="step == 1">
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="keyword" placeholder="Enter a name to search"></ion-input>
				<ion-button color="light" v-if="keyword != null && keyword != ''" @click.prevent="clearSearch()">
					<ion-icon :icon="close" color="dark"></ion-icon>
				</ion-button>
			</ion-item>

			<!-- Query Results -->
			<div v-if="matchedItems.length > 0">
				<ion-list>
					<ion-item v-for="item in matchedItems" :key="item" @click.prevent="setUser(item)" button>
						<div tabindex="0"></div><!-- bug fix for safari 14 -->
						<ion-avatar slot="start">
							<img v-if="item.profilePhoto != null && item.profilePhoto != ''" :src="item.profilePhoto">
							<img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg">
						</ion-avatar>
						<ion-label>
							<h2>{{item.name}}</h2>
							<!--<h3>Guitarist in metal band</h3>-->
							<p>{{item.location}}</p>
						</ion-label>
					</ion-item>
				</ion-list>
			</div>
		</div>
		<!-- Step 2 -->
		<div v-if="step == 2">
			<div>
				<ion-button color="light" @click.prevent="clearSearch()">
					<ion-icon :icon="close" color="dark"></ion-icon>
				</ion-button>
			</div>
			<div v-if="user">
				<ion-item>
					<div tabindex="0"></div><!-- bug fix for safari 14 -->
					<ion-avatar slot="start">
						<img v-if="user.profilePhoto != null && user.profilePhoto != ''" :src="user.profilePhoto">
						<img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg">
					</ion-avatar>
					<ion-label>
						<h2>{{user.name}}</h2>
						<!--<h3>Guitarist in metal band</h3>-->
						<p>{{user.location}}</p>
					</ion-label>
				</ion-item>
				<!-- 
					Status 
				-->
				<ion-item>
					<div tabindex="0"></div><!-- bug fix for safari 14 -->
					<ion-label position="stacked">Status</ion-label>
					<ion-select placeholder="Select One" v-model="status">
						<ion-select-option :value="1">Published</ion-select-option>
						<ion-select-option :value="0">Unpublished</ion-select-option>
					</ion-select>
				</ion-item>
				<!-- Submit -->
				<ion-item>
					<div tabindex="0"></div><!-- bug fix for safari 14 -->
					<ion-button slot="end" @click.prevent="storeRelationship()">
						Save
					</ion-button>
				</ion-item>	
			</div>
		</div>

		<!-- Relationships -->
		<div v-if="pageRelationships.length > 0">
			<div class="padding-md">
				Page Members
			</div>
			<ion-list>
				<ion-item v-for="item in pageRelationships" :key="item">
					<div tabindex="0"></div><!-- bug fix for safari 14 -->
					<ion-avatar slot="start">
						<img v-if="item.user.profile_photo_path != null && item.user.profile_photo_path != ''" :src="item.user.profile_photo_path">
						<img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg">
					</ion-avatar>
					<ion-label>
						<h2>{{item.user.name}}</h2>
					</ion-label>
					<ion-button v-if="item.status == 0" slot="end" color="success" @click.prevent="updateRelationship(item.user.id, 1)">
						<ion-icon :icon="checkmark" class="text-light"></ion-icon>
					</ion-button>
					<ion-button slot="end" color="danger" @click.prevent="updateRelationship(item.user.id, 2)">
						<ion-icon :icon="close" class="text-light"></ion-icon>
					</ion-button>
				</ion-item>
			</ion-list>
		</div>
		<div v-if="pageRelationships.length < 1" class="padding-md text-medium">
			There are no page members. This is an unofficial page.
		</div>
	</div>
</template>

<script>
import { IonItem, IonInput, IonIcon, IonButton, IonAvatar, IonLabel, IonList, IonSelect, IonSelectOption, loadingController, toastController } from '@ionic/vue';
import { defineComponent, computed, onMounted, ref } from 'vue';
import { useStore } from "vuex";
import apiClient from '../services/api';
import { close, checkmark } from 'ionicons/icons';

export default defineComponent({
	name: 'PageRelationships',
	props: ['pageId'],
	components: {
		IonItem, IonInput, IonIcon, IonButton, IonAvatar, IonLabel, IonList, IonSelect, IonSelectOption
	},
	setup(props) {
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const users = ref([]);
		const keyword = ref(null);
		const matchedItems = ref([]);
		const user = ref([]);
		const userId = ref(0);
		const status = ref(1);
		const step = ref(1);
		const toastMessage = ref(null);
		const timeout = { default: 6000 }
		const pageRelationships = ref([]);

		onMounted(() => {
			getUsers()
			getPageRelationships()
		})

		function getUsers() {
			apiClient.get('/api/get/profile/collection',
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				//userCollection.value = response.data
				for(var i=0;i<response.data.length;i++) {
					var location = null
					if(response.data[i].location != null && response.data[i].location != '') {
						location = response.data[i].location
					}

					users.value.push({
						id: response.data[i].id,
						name: response.data[i].name,
						username: response.data[i].username,
						profilePhoto: response.data[i].profile_photo,
						location: location,
						profile_type: response.data[i].profile_type
					})
				}
			}).catch(error => {
				console.log(error)
			});        
		}

		function getPageRelationships() {
			apiClient.get('/api/get/page-relationships/'+props.pageId,
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				pageRelationships.value = response.data
			}).catch(error => {
				console.log(error)
			});        
		}

		function clearSearch() {
			keyword.value = null
			matchedItems.value = []
			step.value = 1
			user.value = []
			userId.value = 0
			status.value = 1
		}

		function searchUsersByKeyword() {
			if(keyword.value != null && keyword.value != '') {
				matchedItems.value = []
				var str = keyword.value
				for(var i=0; i<users.value.length;i++) {
					var username = users.value[i].username.toLowerCase()
					var name = users.value[i].name.toLowerCase()
					str = str.toLowerCase()
			
					if(username.includes(str) || name.includes(str)) {
						matchedItems.value.push({
							id: users.value[i].id,
							name: users.value[i].name,
							username: users.value[i].username,
							profilePhoto: users.value[i].profilePhoto,
							location: users.value[i].location,
							profile_type: users.value[i].profile_type                
						})
					}
					
				}
			}
		}

		function setUser(e) {
			if(e) {
				user.value = e
				userId.value = e.id

				if(userId.value > 0) {
					keyword.value = null
					matchedItems.value = []
					step.value = 2
				}
			}
		}

		function storeRelationship() {
			presentLoading()
			apiClient.post('/api/update/page-relationship/'+props.pageId, 
			{
				user_id: userId.value,
				status: status.value
			},
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			})
			.then(response => {
				// Success
				if(response.data.message == 'success') {
					toastMessage.value = 'You have updated a page relationship.'
					openToast()
					clearSearch()
					getPageRelationships()
				}
				// Fail 
				if(response.data.message == 'fail') {
					toastMessage.value = 'There was a problem.'
					openToast()        
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					toastMessage.value = 'You are not authorized to do this.'
					openToast()
				}
				// Fail Auth
				if(response.data.message == 'fail-exists') {
					toastMessage.value = 'That relationship exists.'
					openToast()
				}
			})
			.catch(error => {
				// credentials didn't match
				console.log(error)
			}); 			
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
				{
					icon: 'close',
					role: 'cancel',
					handler: () => {

					}
				}]
			})
			return toast.present();
		}

		function updateRelationship(id, val) {
			presentLoading()
			apiClient.post('/api/update/page-relationship-status/'+props.pageId, 
			{
				user_id: id,
				status: val
			},
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			})
			.then(response => {
				// Success
				if(response.data.message == 'success') {
					toastMessage.value = 'You have updated a page relationship.'
					openToast()
					clearSearch()
					getPageRelationships()
				}
				// Success
				if(response.data.message == 'success-delete') {
					toastMessage.value = 'You have deleted a page relationship.'
					openToast()
					clearSearch()
					getPageRelationships()
				}
				// Fail 
				if(response.data.message == 'fail') {
					toastMessage.value = 'There was a problem.'
					openToast()        
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					toastMessage.value = 'You are not authorized to do this.'
					openToast()
				}
				// Fail Auth
				if(response.data.message == 'fail-exists') {
					toastMessage.value = 'That relationship exists.'
					openToast()
				}
			})
			.catch(error => {
				// credentials didn't match
				console.log(error)
			});			
		}

		return {
			authUser, users, keyword, close, clearSearch, matchedItems, searchUsersByKeyword, setUser, user, userId, step, storeRelationship, status, pageRelationships, getPageRelationships, updateRelationship, checkmark
		}
	},
	watch: {
		keyword: function() {
			if(this.keyword == '') {
				this.keyword = null
			}
			if(this.keyword === null) {
				this.matchedItems = []
			} else {
				if(this.keyword.length >= 2) {
					this.searchUsersByKeyword()
				}
			}			
		}
	}
});
</script>

