<template>
	<div>
		<div v-if="posts.length > 0">
			<ion-card v-for="post in posts" :key="post">
				<!-- Post has link with image -->
				<div v-if="post.link_id != 0">
					<div v-if="post.link_data != null">
						<div v-if="post.link_data.cover_image != null && post.link_data.cover_image != ''">
							<a :href="post.link_data.url" target="_blank" class="text-cap" style="text-decoration:none;">
								<ion-img :src="post.link_data.cover_image" width="100%" />
							</a>
						</div>
						<!-- Post does not have link with image -->
						<div v-else>
							<div v-if="post.filename != null && post.filename != ''">
								<ion-img :src="post.filename" width="100%"  @click.prevent="viewPost(post.slug)" />
							</div>						
						</div>
					</div>
					<!-- Post does not have link with image -->
					<div v-else>
						<div v-if="post.filename != null && post.filename != ''">
							<ion-img :src="post.filename" width="100%"  @click.prevent="viewPost(post.slug)" />
						</div>						
					</div>
				</div>
				<!-- Post does not have link with image -->
				<div v-else>
					<div v-if="post.filename != null && post.filename != ''">
						<ion-img :src="post.filename" width="100%"  @click.prevent="viewPost(post.slug)" />
					</div>						
				</div>
				<!-- Header -->
				<ion-card-header>
					<div v-if="post.link_data != null">
						<ion-card-subtitle><ion-icon :icon="link" /> Link</ion-card-subtitle>
						<div v-if="post.link_data.title != null && post.link_data.title != ''">
							<ion-card-title>
								<a :href="post.link_data.url" target="_blank" class="text-cap" style="text-decoration:none;">
									{{post.link_data.title}}
								</a>
							</ion-card-title>
						</div>
					</div>
					<div v-if="post.link_id != 0">
						<div v-if="post.title != null && post.title != ''">
							<ion-card-title>
								{{post.title}}
							</ion-card-title>
						</div>
					</div>
				</ion-card-header>
				<!-- Post Content -->
				<ion-card-content>

					<!-- URL -->
					<div v-if="post.link_id != 0">
						<div v-if="post.link_data != null">
							<div v-if="post.link_data.description != null && post.link_data.description != ''">
								{{post.link_data.description}}
							</div>
						</div>
						<div v-else>
							{{post.link_data.url}}
						</div>
					</div>	
					<div v-else>
						<!-- Body -->
						<div v-if="post.body != null && post.body != ''">
							<div v-html="post.body"></div>
						</div>

					</div>
					<div class="text-right">
						<ion-button color="light" :href="'/admin-post/'+post.id">
							<ion-icon :icon="ellipsisHorizontal" />
						</ion-button>
					</div>	
				</ion-card-content>
			</ion-card>
		</div>
		<div v-else>
			No Posts
		</div>
	</div>
</template>

<script>
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonCardContent } from '@ionic/vue';
import { defineComponent, computed, onMounted, ref } from 'vue';
import { useStore } from "vuex";
import apiClient from '../services/api';
import { link, ellipsisHorizontal } from 'ionicons/icons';

export default defineComponent({
	name: 'PagePosts',
	props: ['pageId'],
	components: {
		IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonCardContent
	},
	setup(props) {
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const posts = ref([]);

		onMounted(() => {
			getPosts()
		})

		function getPosts() {
			//presentLoading()
			apiClient.get('/api/page/posts/'+props.pageId, {
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				posts.value = response.data

			}).catch(error => {
				console.log(error)  
			});      
		}

		return {
			authUser, link, posts, ellipsisHorizontal
		}
	}
});
</script>

